import React from "react"
import {Code, Container, Text} from "./styles"
import {PrimaryButton} from "components/Buttons"

const ErrorPage = ({code, text}) => {
  return (
    <Container>
      <Code>{code}</Code>
      <Text>{text}</Text>
      <PrimaryButton to="/">Strona główna</PrimaryButton>
    </Container>
  )
}

export default ErrorPage
