import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 96px 48px 64px 48px;
`

export const Code = styled.div`
  font-size: 200px;
  font-weight: 700;
  padding: 64px 48px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 140px;
  }
`

export const Text = styled.div`
  margin: 36px 0 24px 0;
  font-size: 20px;
  text-align: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 16px;
  }
`
