import * as React from "react"
import SEO from "components/seo"

import ErrorPage from "sections/ErrorPage/ErrorPage"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <ErrorPage code="404" text="Strona o podanym adresie nie istnieje!" />
  </>
)

export default NotFoundPage
